// Auto Generated, Don't Modify Manually
import * as SdkL1 from './sdk-l1'
import { refreshTokenOnError } from '../hooks/auth/refreshTokenOnError'

export const deleteAuthToken = refreshTokenOnError<SdkL1.deleteAuthTokenParams, SdkL1.deleteAuthTokenOk, SdkL1.deleteAuthTokenErr, typeof SdkL1.deleteAuthToken>(SdkL1.deleteAuthToken)
export const getAuthUser = refreshTokenOnError<SdkL1.getAuthUserParams, SdkL1.getAuthUserOk, SdkL1.getAuthUserErr, typeof SdkL1.getAuthUser>(SdkL1.getAuthUser)
export const postPushSubscriptions = refreshTokenOnError<SdkL1.postPushSubscriptionsParams, SdkL1.postPushSubscriptionsOk, SdkL1.postPushSubscriptionsErr, typeof SdkL1.postPushSubscriptions>(SdkL1.postPushSubscriptions)
export const postPaperCriterias = refreshTokenOnError<SdkL1.postPaperCriteriasParams, SdkL1.postPaperCriteriasOk, SdkL1.postPaperCriteriasErr, typeof SdkL1.postPaperCriterias>(SdkL1.postPaperCriterias)
export const getLecturerGroupsExport = refreshTokenOnError<SdkL1.getLecturerGroupsExportParams, SdkL1.getLecturerGroupsExportOk, SdkL1.getLecturerGroupsExportErr, typeof SdkL1.getLecturerGroupsExport>(SdkL1.getLecturerGroupsExport)
export const updatePaperCriterias = refreshTokenOnError<SdkL1.updatePaperCriteriasParams, SdkL1.updatePaperCriteriasOk, SdkL1.updatePaperCriteriasErr, typeof SdkL1.updatePaperCriterias>(SdkL1.updatePaperCriterias)
export const getPaperCriterias = refreshTokenOnError<SdkL1.getPaperCriteriasParams, SdkL1.getPaperCriteriasOk, SdkL1.getPaperCriteriasErr, typeof SdkL1.getPaperCriterias>(SdkL1.getPaperCriterias)
export const getLecturerTeachingGroups = refreshTokenOnError<SdkL1.getLecturerTeachingGroupsParams, SdkL1.getLecturerTeachingGroupsOk, SdkL1.getLecturerTeachingGroupsErr, typeof SdkL1.getLecturerTeachingGroups>(SdkL1.getLecturerTeachingGroups)
export const getLecturerGroupsPaper = refreshTokenOnError<SdkL1.getLecturerGroupsPaperParams, SdkL1.getLecturerGroupsPaperOk, SdkL1.getLecturerGroupsPaperErr, typeof SdkL1.getLecturerGroupsPaper>(SdkL1.getLecturerGroupsPaper)
export const getLecturerStudents = refreshTokenOnError<SdkL1.getLecturerStudentsParams, SdkL1.getLecturerStudentsOk, SdkL1.getLecturerStudentsErr, typeof SdkL1.getLecturerStudents>(SdkL1.getLecturerStudents)
export const postStudentsScore = refreshTokenOnError<SdkL1.postStudentsScoreParams, SdkL1.postStudentsScoreOk, SdkL1.postStudentsScoreErr, typeof SdkL1.postStudentsScore>(SdkL1.postStudentsScore)
export const getLecturerTeachingGroup = refreshTokenOnError<SdkL1.getLecturerTeachingGroupParams, SdkL1.getLecturerTeachingGroupOk, SdkL1.getLecturerTeachingGroupErr, typeof SdkL1.getLecturerTeachingGroup>(SdkL1.getLecturerTeachingGroup)
export const getLecturerTeachesSemesters = refreshTokenOnError<SdkL1.getLecturerTeachesSemestersParams, SdkL1.getLecturerTeachesSemestersOk, SdkL1.getLecturerTeachesSemestersErr, typeof SdkL1.getLecturerTeachesSemesters>(SdkL1.getLecturerTeachesSemesters)
export const getLecturerCourseTeachesSemesters = refreshTokenOnError<SdkL1.getLecturerCourseTeachesSemestersParams, SdkL1.getLecturerCourseTeachesSemestersOk, SdkL1.getLecturerCourseTeachesSemestersErr, typeof SdkL1.getLecturerCourseTeachesSemesters>(SdkL1.getLecturerCourseTeachesSemesters)
export const getLecturerManageSemesters = refreshTokenOnError<SdkL1.getLecturerManageSemestersParams, SdkL1.getLecturerManageSemestersOk, SdkL1.getLecturerManageSemestersErr, typeof SdkL1.getLecturerManageSemesters>(SdkL1.getLecturerManageSemesters)
export const getLecturerCourse = refreshTokenOnError<SdkL1.getLecturerCourseParams, SdkL1.getLecturerCourseOk, SdkL1.getLecturerCourseErr, typeof SdkL1.getLecturerCourse>(SdkL1.getLecturerCourse)
export const getLecturerCourseSyllabus = refreshTokenOnError<SdkL1.getLecturerCourseSyllabusParams, SdkL1.getLecturerCourseSyllabusOk, SdkL1.getLecturerCourseSyllabusErr, typeof SdkL1.getLecturerCourseSyllabus>(SdkL1.getLecturerCourseSyllabus)
export const patchLecturerCourseSyllabus = refreshTokenOnError<SdkL1.patchLecturerCourseSyllabusParams, SdkL1.patchLecturerCourseSyllabusOk, SdkL1.patchLecturerCourseSyllabusErr, typeof SdkL1.patchLecturerCourseSyllabus>(SdkL1.patchLecturerCourseSyllabus)
export const getRegimesActive = refreshTokenOnError<SdkL1.getRegimesActiveParams, SdkL1.getRegimesActiveOk, SdkL1.getRegimesActiveErr, typeof SdkL1.getRegimesActive>(SdkL1.getRegimesActive)
export const getStudentChoices = refreshTokenOnError<SdkL1.getStudentChoicesParams, SdkL1.getStudentChoicesOk, SdkL1.getStudentChoicesErr, typeof SdkL1.getStudentChoices>(SdkL1.getStudentChoices)
export const postStudentChoicesProgramsAssign = refreshTokenOnError<SdkL1.postStudentChoicesProgramsAssignParams, SdkL1.postStudentChoicesProgramsAssignOk, SdkL1.postStudentChoicesProgramsAssignErr, typeof SdkL1.postStudentChoicesProgramsAssign>(SdkL1.postStudentChoicesProgramsAssign)
export const postStudentChoicesFreeCreditsAssign = refreshTokenOnError<SdkL1.postStudentChoicesFreeCreditsAssignParams, SdkL1.postStudentChoicesFreeCreditsAssignOk, SdkL1.postStudentChoicesFreeCreditsAssignErr, typeof SdkL1.postStudentChoicesFreeCreditsAssign>(SdkL1.postStudentChoicesFreeCreditsAssign)
export const postStudentChoicesRemoveAssignment = refreshTokenOnError<SdkL1.postStudentChoicesRemoveAssignmentParams, SdkL1.postStudentChoicesRemoveAssignmentOk, SdkL1.postStudentChoicesRemoveAssignmentErr, typeof SdkL1.postStudentChoicesRemoveAssignment>(SdkL1.postStudentChoicesRemoveAssignment)
export const postStudentChoices = refreshTokenOnError<SdkL1.postStudentChoicesParams, SdkL1.postStudentChoicesOk, SdkL1.postStudentChoicesErr, typeof SdkL1.postStudentChoices>(SdkL1.postStudentChoices)
export const deleteStudentChoices = refreshTokenOnError<SdkL1.deleteStudentChoicesParams, SdkL1.deleteStudentChoicesOk, SdkL1.deleteStudentChoicesErr, typeof SdkL1.deleteStudentChoices>(SdkL1.deleteStudentChoices)
export const getStudentChoicesRechoose = refreshTokenOnError<SdkL1.getStudentChoicesRechooseParams, SdkL1.getStudentChoicesRechooseOk, SdkL1.getStudentChoicesRechooseErr, typeof SdkL1.getStudentChoicesRechoose>(SdkL1.getStudentChoicesRechoose)
export const postStudentChoicesRechoose = refreshTokenOnError<SdkL1.postStudentChoicesRechooseParams, SdkL1.postStudentChoicesRechooseOk, SdkL1.postStudentChoicesRechooseErr, typeof SdkL1.postStudentChoicesRechoose>(SdkL1.postStudentChoicesRechoose)
export const deleteStudentChoicesRechoose = refreshTokenOnError<SdkL1.deleteStudentChoicesRechooseParams, SdkL1.deleteStudentChoicesRechooseOk, SdkL1.deleteStudentChoicesRechooseErr, typeof SdkL1.deleteStudentChoicesRechoose>(SdkL1.deleteStudentChoicesRechoose)
export const getStudentSchedulesCurrent = refreshTokenOnError<SdkL1.getStudentSchedulesCurrentParams, SdkL1.getStudentSchedulesCurrentOk, SdkL1.getStudentSchedulesCurrentErr, typeof SdkL1.getStudentSchedulesCurrent>(SdkL1.getStudentSchedulesCurrent)
export const getStudentCard = refreshTokenOnError<SdkL1.getStudentCardParams, SdkL1.getStudentCardOk, SdkL1.getStudentCardErr, typeof SdkL1.getStudentCard>(SdkL1.getStudentCard)
export const getStudentCardExport = refreshTokenOnError<SdkL1.getStudentCardExportParams, SdkL1.getStudentCardExportOk, SdkL1.getStudentCardExportErr, typeof SdkL1.getStudentCardExport>(SdkL1.getStudentCardExport)
export const getStudentCalendar = refreshTokenOnError<SdkL1.getStudentCalendarParams, SdkL1.getStudentCalendarOk, SdkL1.getStudentCalendarErr, typeof SdkL1.getStudentCalendar>(SdkL1.getStudentCalendar)
export const getStudentCourse = refreshTokenOnError<SdkL1.getStudentCourseParams, SdkL1.getStudentCourseOk, SdkL1.getStudentCourseErr, typeof SdkL1.getStudentCourse>(SdkL1.getStudentCourse)
export const getStudentCourseSyllabus = refreshTokenOnError<SdkL1.getStudentCourseSyllabusParams, SdkL1.getStudentCourseSyllabusOk, SdkL1.getStudentCourseSyllabusErr, typeof SdkL1.getStudentCourseSyllabus>(SdkL1.getStudentCourseSyllabus)
export const getStudentCourseGroups = refreshTokenOnError<SdkL1.getStudentCourseGroupsParams, SdkL1.getStudentCourseGroupsOk, SdkL1.getStudentCourseGroupsErr, typeof SdkL1.getStudentCourseGroups>(SdkL1.getStudentCourseGroups)
export const getStudentCourseClassmates = refreshTokenOnError<SdkL1.getStudentCourseClassmatesParams, SdkL1.getStudentCourseClassmatesOk, SdkL1.getStudentCourseClassmatesErr, typeof SdkL1.getStudentCourseClassmates>(SdkL1.getStudentCourseClassmates)
export const getStudentCourseGroupSchedule = refreshTokenOnError<SdkL1.getStudentCourseGroupScheduleParams, SdkL1.getStudentCourseGroupScheduleOk, SdkL1.getStudentCourseGroupScheduleErr, typeof SdkL1.getStudentCourseGroupSchedule>(SdkL1.getStudentCourseGroupSchedule)
export const getStudentCourseGroupWeekSchedule = refreshTokenOnError<SdkL1.getStudentCourseGroupWeekScheduleParams, SdkL1.getStudentCourseGroupWeekScheduleOk, SdkL1.getStudentCourseGroupWeekScheduleErr, typeof SdkL1.getStudentCourseGroupWeekSchedule>(SdkL1.getStudentCourseGroupWeekSchedule)
export const getStudentCourseScores = refreshTokenOnError<SdkL1.getStudentCourseScoresParams, SdkL1.getStudentCourseScoresOk, SdkL1.getStudentCourseScoresErr, typeof SdkL1.getStudentCourseScores>(SdkL1.getStudentCourseScores)
export const getStudentCoursesMaterials = refreshTokenOnError<SdkL1.getStudentCoursesMaterialsParams, SdkL1.getStudentCoursesMaterialsOk, SdkL1.getStudentCoursesMaterialsErr, typeof SdkL1.getStudentCoursesMaterials>(SdkL1.getStudentCoursesMaterials)
export const getStudentCoursePosts = refreshTokenOnError<SdkL1.getStudentCoursePostsParams, SdkL1.getStudentCoursePostsOk, SdkL1.getStudentCoursePostsErr, typeof SdkL1.getStudentCoursePosts>(SdkL1.getStudentCoursePosts)
export const getStudentCoursePostComments = refreshTokenOnError<SdkL1.getStudentCoursePostCommentsParams, SdkL1.getStudentCoursePostCommentsOk, SdkL1.getStudentCoursePostCommentsErr, typeof SdkL1.getStudentCoursePostComments>(SdkL1.getStudentCoursePostComments)
export const postStudentCoursesPostsComments = refreshTokenOnError<SdkL1.postStudentCoursesPostsCommentsParams, SdkL1.postStudentCoursesPostsCommentsOk, SdkL1.postStudentCoursesPostsCommentsErr, typeof SdkL1.postStudentCoursesPostsComments>(SdkL1.postStudentCoursesPostsComments)
export const getStudentStatements = refreshTokenOnError<SdkL1.getStudentStatementsParams, SdkL1.getStudentStatementsOk, SdkL1.getStudentStatementsErr, typeof SdkL1.getStudentStatements>(SdkL1.getStudentStatements)
export const getStudentStatement = refreshTokenOnError<SdkL1.getStudentStatementParams, SdkL1.getStudentStatementOk, SdkL1.getStudentStatementErr, typeof SdkL1.getStudentStatement>(SdkL1.getStudentStatement)
export const postLecturerCalendar = refreshTokenOnError<SdkL1.postLecturerCalendarParams, SdkL1.postLecturerCalendarOk, SdkL1.postLecturerCalendarErr, typeof SdkL1.postLecturerCalendar>(SdkL1.postLecturerCalendar)
export const getLecturerCalendar = refreshTokenOnError<SdkL1.getLecturerCalendarParams, SdkL1.getLecturerCalendarOk, SdkL1.getLecturerCalendarErr, typeof SdkL1.getLecturerCalendar>(SdkL1.getLecturerCalendar)
export const deleteLecturerCalendar = refreshTokenOnError<SdkL1.deleteLecturerCalendarParams, SdkL1.deleteLecturerCalendarOk, SdkL1.deleteLecturerCalendarErr, typeof SdkL1.deleteLecturerCalendar>(SdkL1.deleteLecturerCalendar)
export const getCoursesLecturers = refreshTokenOnError<SdkL1.getCoursesLecturersParams, SdkL1.getCoursesLecturersOk, SdkL1.getCoursesLecturersErr, typeof SdkL1.getCoursesLecturers>(SdkL1.getCoursesLecturers)
export const getLecturerCoursesMaterials = refreshTokenOnError<SdkL1.getLecturerCoursesMaterialsParams, SdkL1.getLecturerCoursesMaterialsOk, SdkL1.getLecturerCoursesMaterialsErr, typeof SdkL1.getLecturerCoursesMaterials>(SdkL1.getLecturerCoursesMaterials)
export const postLecturerCoursesMaterials = refreshTokenOnError<SdkL1.postLecturerCoursesMaterialsParams, SdkL1.postLecturerCoursesMaterialsOk, SdkL1.postLecturerCoursesMaterialsErr, typeof SdkL1.postLecturerCoursesMaterials>(SdkL1.postLecturerCoursesMaterials)
export const patchLecturerCoursesMaterials = refreshTokenOnError<SdkL1.patchLecturerCoursesMaterialsParams, SdkL1.patchLecturerCoursesMaterialsOk, SdkL1.patchLecturerCoursesMaterialsErr, typeof SdkL1.patchLecturerCoursesMaterials>(SdkL1.patchLecturerCoursesMaterials)
export const deleteLecturerCoursesMaterials = refreshTokenOnError<SdkL1.deleteLecturerCoursesMaterialsParams, SdkL1.deleteLecturerCoursesMaterialsOk, SdkL1.deleteLecturerCoursesMaterialsErr, typeof SdkL1.deleteLecturerCoursesMaterials>(SdkL1.deleteLecturerCoursesMaterials)
export const getLecturerCoursesMaterial = refreshTokenOnError<SdkL1.getLecturerCoursesMaterialParams, SdkL1.getLecturerCoursesMaterialOk, SdkL1.getLecturerCoursesMaterialErr, typeof SdkL1.getLecturerCoursesMaterial>(SdkL1.getLecturerCoursesMaterial)
export const getLecturerCoursesStudents = refreshTokenOnError<SdkL1.getLecturerCoursesStudentsParams, SdkL1.getLecturerCoursesStudentsOk, SdkL1.getLecturerCoursesStudentsErr, typeof SdkL1.getLecturerCoursesStudents>(SdkL1.getLecturerCoursesStudents)
export const getLecturerSchedulesCurrent = refreshTokenOnError<SdkL1.getLecturerSchedulesCurrentParams, SdkL1.getLecturerSchedulesCurrentOk, SdkL1.getLecturerSchedulesCurrentErr, typeof SdkL1.getLecturerSchedulesCurrent>(SdkL1.getLecturerSchedulesCurrent)
export const getLecturerCoursesTeaches = refreshTokenOnError<SdkL1.getLecturerCoursesTeachesParams, SdkL1.getLecturerCoursesTeachesOk, SdkL1.getLecturerCoursesTeachesErr, typeof SdkL1.getLecturerCoursesTeaches>(SdkL1.getLecturerCoursesTeaches)
export const getLecturerCoursesManage = refreshTokenOnError<SdkL1.getLecturerCoursesManageParams, SdkL1.getLecturerCoursesManageOk, SdkL1.getLecturerCoursesManageErr, typeof SdkL1.getLecturerCoursesManage>(SdkL1.getLecturerCoursesManage)
export const getLecturerFeedbacksSemesters = refreshTokenOnError<SdkL1.getLecturerFeedbacksSemestersParams, SdkL1.getLecturerFeedbacksSemestersOk, SdkL1.getLecturerFeedbacksSemestersErr, typeof SdkL1.getLecturerFeedbacksSemesters>(SdkL1.getLecturerFeedbacksSemesters)
export const getLecturerFeedbacksSubjects = refreshTokenOnError<SdkL1.getLecturerFeedbacksSubjectsParams, SdkL1.getLecturerFeedbacksSubjectsOk, SdkL1.getLecturerFeedbacksSubjectsErr, typeof SdkL1.getLecturerFeedbacksSubjects>(SdkL1.getLecturerFeedbacksSubjects)
export const postLecturerFeedbacks = refreshTokenOnError<SdkL1.postLecturerFeedbacksParams, SdkL1.postLecturerFeedbacksOk, SdkL1.postLecturerFeedbacksErr, typeof SdkL1.postLecturerFeedbacks>(SdkL1.postLecturerFeedbacks)
export const getLecturerFeedback = refreshTokenOnError<SdkL1.getLecturerFeedbackParams, SdkL1.getLecturerFeedbackOk, SdkL1.getLecturerFeedbackErr, typeof SdkL1.getLecturerFeedback>(SdkL1.getLecturerFeedback)
export const getLecturerFeedbacks = refreshTokenOnError<SdkL1.getLecturerFeedbacksParams, SdkL1.getLecturerFeedbacksOk, SdkL1.getLecturerFeedbacksErr, typeof SdkL1.getLecturerFeedbacks>(SdkL1.getLecturerFeedbacks)
export const getStudentStatementsTemplates = refreshTokenOnError<SdkL1.getStudentStatementsTemplatesParams, SdkL1.getStudentStatementsTemplatesOk, SdkL1.getStudentStatementsTemplatesErr, typeof SdkL1.getStudentStatementsTemplates>(SdkL1.getStudentStatementsTemplates)
export const getStudentStatementTemplate = refreshTokenOnError<SdkL1.getStudentStatementTemplateParams, SdkL1.getStudentStatementTemplateOk, SdkL1.getStudentStatementTemplateErr, typeof SdkL1.getStudentStatementTemplate>(SdkL1.getStudentStatementTemplate)
export const getStudentStatementsSemesters = refreshTokenOnError<SdkL1.getStudentStatementsSemestersParams, SdkL1.getStudentStatementsSemestersOk, SdkL1.getStudentStatementsSemestersErr, typeof SdkL1.getStudentStatementsSemesters>(SdkL1.getStudentStatementsSemesters)
export const getStudentFeedbacksSemesters = refreshTokenOnError<SdkL1.getStudentFeedbacksSemestersParams, SdkL1.getStudentFeedbacksSemestersOk, SdkL1.getStudentFeedbacksSemestersErr, typeof SdkL1.getStudentFeedbacksSemesters>(SdkL1.getStudentFeedbacksSemesters)
export const getStudentStatementsFaculties = refreshTokenOnError<SdkL1.getStudentStatementsFacultiesParams, SdkL1.getStudentStatementsFacultiesOk, SdkL1.getStudentStatementsFacultiesErr, typeof SdkL1.getStudentStatementsFaculties>(SdkL1.getStudentStatementsFaculties)
export const getStudentStatementsFacultiesPrograms = refreshTokenOnError<SdkL1.getStudentStatementsFacultiesProgramsParams, SdkL1.getStudentStatementsFacultiesProgramsOk, SdkL1.getStudentStatementsFacultiesProgramsErr, typeof SdkL1.getStudentStatementsFacultiesPrograms>(SdkL1.getStudentStatementsFacultiesPrograms)
export const postStudentStatements = refreshTokenOnError<SdkL1.postStudentStatementsParams, SdkL1.postStudentStatementsOk, SdkL1.postStudentStatementsErr, typeof SdkL1.postStudentStatements>(SdkL1.postStudentStatements)
export const getStudentCoursesCatalog = refreshTokenOnError<SdkL1.getStudentCoursesCatalogParams, SdkL1.getStudentCoursesCatalogOk, SdkL1.getStudentCoursesCatalogErr, typeof SdkL1.getStudentCoursesCatalog>(SdkL1.getStudentCoursesCatalog)
export const getLibraryBooks = refreshTokenOnError<SdkL1.getLibraryBooksParams, SdkL1.getLibraryBooksOk, SdkL1.getLibraryBooksErr, typeof SdkL1.getLibraryBooks>(SdkL1.getLibraryBooks)
export const getLibraryBooksTaken = refreshTokenOnError<SdkL1.getLibraryBooksTakenParams, SdkL1.getLibraryBooksTakenOk, SdkL1.getLibraryBooksTakenErr, typeof SdkL1.getLibraryBooksTaken>(SdkL1.getLibraryBooksTaken)
export const getStudentBillingBalance = refreshTokenOnError<SdkL1.getStudentBillingBalanceParams, SdkL1.getStudentBillingBalanceOk, SdkL1.getStudentBillingBalanceErr, typeof SdkL1.getStudentBillingBalance>(SdkL1.getStudentBillingBalance)
export const getStudentBillingInstructions = refreshTokenOnError<SdkL1.getStudentBillingInstructionsParams, SdkL1.getStudentBillingInstructionsOk, SdkL1.getStudentBillingInstructionsErr, typeof SdkL1.getStudentBillingInstructions>(SdkL1.getStudentBillingInstructions)
export const getStudentBillingDeposits = refreshTokenOnError<SdkL1.getStudentBillingDepositsParams, SdkL1.getStudentBillingDepositsOk, SdkL1.getStudentBillingDepositsErr, typeof SdkL1.getStudentBillingDeposits>(SdkL1.getStudentBillingDeposits)
export const getStudentBillingSchedule = refreshTokenOnError<SdkL1.getStudentBillingScheduleParams, SdkL1.getStudentBillingScheduleOk, SdkL1.getStudentBillingScheduleErr, typeof SdkL1.getStudentBillingSchedule>(SdkL1.getStudentBillingSchedule)
export const getUserSettingsGeneral = refreshTokenOnError<SdkL1.getUserSettingsGeneralParams, SdkL1.getUserSettingsGeneralOk, SdkL1.getUserSettingsGeneralErr, typeof SdkL1.getUserSettingsGeneral>(SdkL1.getUserSettingsGeneral)
export const putUserSettingsGeneral = refreshTokenOnError<SdkL1.putUserSettingsGeneralParams, SdkL1.putUserSettingsGeneralOk, SdkL1.putUserSettingsGeneralErr, typeof SdkL1.putUserSettingsGeneral>(SdkL1.putUserSettingsGeneral)
export const getUserSettingsNotifications = refreshTokenOnError<SdkL1.getUserSettingsNotificationsParams, SdkL1.getUserSettingsNotificationsOk, SdkL1.getUserSettingsNotificationsErr, typeof SdkL1.getUserSettingsNotifications>(SdkL1.getUserSettingsNotifications)
export const putUserSettingsNotifications = refreshTokenOnError<SdkL1.putUserSettingsNotificationsParams, SdkL1.putUserSettingsNotificationsOk, SdkL1.putUserSettingsNotificationsErr, typeof SdkL1.putUserSettingsNotifications>(SdkL1.putUserSettingsNotifications)
export const getUserAuthLog = refreshTokenOnError<SdkL1.getUserAuthLogParams, SdkL1.getUserAuthLogOk, SdkL1.getUserAuthLogErr, typeof SdkL1.getUserAuthLog>(SdkL1.getUserAuthLog)
export const getUserSemestersAvailable = refreshTokenOnError<SdkL1.getUserSemestersAvailableParams, SdkL1.getUserSemestersAvailableOk, SdkL1.getUserSemestersAvailableErr, typeof SdkL1.getUserSemestersAvailable>(SdkL1.getUserSemestersAvailable)
export const getUserState = refreshTokenOnError<SdkL1.getUserStateParams, SdkL1.getUserStateOk, SdkL1.getUserStateErr, typeof SdkL1.getUserState>(SdkL1.getUserState)
export const patchUserPassword = refreshTokenOnError<SdkL1.patchUserPasswordParams, SdkL1.patchUserPasswordOk, SdkL1.patchUserPasswordErr, typeof SdkL1.patchUserPassword>(SdkL1.patchUserPassword)
export const patchUserContact = refreshTokenOnError<SdkL1.patchUserContactParams, SdkL1.patchUserContactOk, SdkL1.patchUserContactErr, typeof SdkL1.patchUserContact>(SdkL1.patchUserContact)
export const getUser = refreshTokenOnError<SdkL1.getUserParams, SdkL1.getUserOk, SdkL1.getUserErr, typeof SdkL1.getUser>(SdkL1.getUser)
export const getMessagesInbox = refreshTokenOnError<SdkL1.getMessagesInboxParams, SdkL1.getMessagesInboxOk, SdkL1.getMessagesInboxErr, typeof SdkL1.getMessagesInbox>(SdkL1.getMessagesInbox)
export const getMessagesOutbox = refreshTokenOnError<SdkL1.getMessagesOutboxParams, SdkL1.getMessagesOutboxOk, SdkL1.getMessagesOutboxErr, typeof SdkL1.getMessagesOutbox>(SdkL1.getMessagesOutbox)
export const getMessage = refreshTokenOnError<SdkL1.getMessageParams, SdkL1.getMessageOk, SdkL1.getMessageErr, typeof SdkL1.getMessage>(SdkL1.getMessage)
export const getStudentByMessage = refreshTokenOnError<SdkL1.getStudentByMessageParams, SdkL1.getStudentByMessageOk, SdkL1.getStudentByMessageErr, typeof SdkL1.getStudentByMessage>(SdkL1.getStudentByMessage)
export const getRecommendation = refreshTokenOnError<SdkL1.getRecommendationParams, SdkL1.getRecommendationOk, SdkL1.getRecommendationErr, typeof SdkL1.getRecommendation>(SdkL1.getRecommendation)
export const getRecommendationStudentInfo = refreshTokenOnError<SdkL1.getRecommendationStudentInfoParams, SdkL1.getRecommendationStudentInfoOk, SdkL1.getRecommendationStudentInfoErr, typeof SdkL1.getRecommendationStudentInfo>(SdkL1.getRecommendationStudentInfo)
export const getMessagesUnreadCount = refreshTokenOnError<SdkL1.getMessagesUnreadCountParams, SdkL1.getMessagesUnreadCountOk, SdkL1.getMessagesUnreadCountErr, typeof SdkL1.getMessagesUnreadCount>(SdkL1.getMessagesUnreadCount)
export const getMessagesExport = refreshTokenOnError<SdkL1.getMessagesExportParams, SdkL1.getMessagesExportOk, SdkL1.getMessagesExportErr, typeof SdkL1.getMessagesExport>(SdkL1.getMessagesExport)
export const postMessagesToStudents = refreshTokenOnError<SdkL1.postMessagesToStudentsParams, SdkL1.postMessagesToStudentsOk, SdkL1.postMessagesToStudentsErr, typeof SdkL1.postMessagesToStudents>(SdkL1.postMessagesToStudents)
export const postMessagesToGroups = refreshTokenOnError<SdkL1.postMessagesToGroupsParams, SdkL1.postMessagesToGroupsOk, SdkL1.postMessagesToGroupsErr, typeof SdkL1.postMessagesToGroups>(SdkL1.postMessagesToGroups)
export const postMessages = refreshTokenOnError<SdkL1.postMessagesParams, SdkL1.postMessagesOk, SdkL1.postMessagesErr, typeof SdkL1.postMessages>(SdkL1.postMessages)
export const postLecturerRecommendations = refreshTokenOnError<SdkL1.postLecturerRecommendationsParams, SdkL1.postLecturerRecommendationsOk, SdkL1.postLecturerRecommendationsErr, typeof SdkL1.postLecturerRecommendations>(SdkL1.postLecturerRecommendations)
export const getAdministratorUsers = refreshTokenOnError<SdkL1.getAdministratorUsersParams, SdkL1.getAdministratorUsersOk, SdkL1.getAdministratorUsersErr, typeof SdkL1.getAdministratorUsers>(SdkL1.getAdministratorUsers)
export const patchMessagesMarkRead = refreshTokenOnError<SdkL1.patchMessagesMarkReadParams, SdkL1.patchMessagesMarkReadOk, SdkL1.patchMessagesMarkReadErr, typeof SdkL1.patchMessagesMarkRead>(SdkL1.patchMessagesMarkRead)
export const patchMessagesMarkUnread = refreshTokenOnError<SdkL1.patchMessagesMarkUnreadParams, SdkL1.patchMessagesMarkUnreadOk, SdkL1.patchMessagesMarkUnreadErr, typeof SdkL1.patchMessagesMarkUnread>(SdkL1.patchMessagesMarkUnread)
export const deleteMessages = refreshTokenOnError<SdkL1.deleteMessagesParams, SdkL1.deleteMessagesOk, SdkL1.deleteMessagesErr, typeof SdkL1.deleteMessages>(SdkL1.deleteMessages)
export const getStudentRating = refreshTokenOnError<SdkL1.getStudentRatingParams, SdkL1.getStudentRatingOk, SdkL1.getStudentRatingErr, typeof SdkL1.getStudentRating>(SdkL1.getStudentRating)
export const getNews = refreshTokenOnError<SdkL1.getNewsParams, SdkL1.getNewsOk, SdkL1.getNewsErr, typeof SdkL1.getNews>(SdkL1.getNews)
export const getStudentPortfolioSections = refreshTokenOnError<SdkL1.getStudentPortfolioSectionsParams, SdkL1.getStudentPortfolioSectionsOk, SdkL1.getStudentPortfolioSectionsErr, typeof SdkL1.getStudentPortfolioSections>(SdkL1.getStudentPortfolioSections)
export const getLecturerPortfolioSections = refreshTokenOnError<SdkL1.getLecturerPortfolioSectionsParams, SdkL1.getLecturerPortfolioSectionsOk, SdkL1.getLecturerPortfolioSectionsErr, typeof SdkL1.getLecturerPortfolioSections>(SdkL1.getLecturerPortfolioSections)
export const getStudentPortfolioSection = refreshTokenOnError<SdkL1.getStudentPortfolioSectionParams, SdkL1.getStudentPortfolioSectionOk, SdkL1.getStudentPortfolioSectionErr, typeof SdkL1.getStudentPortfolioSection>(SdkL1.getStudentPortfolioSection)
export const getLecturerPortfolioSection = refreshTokenOnError<SdkL1.getLecturerPortfolioSectionParams, SdkL1.getLecturerPortfolioSectionOk, SdkL1.getLecturerPortfolioSectionErr, typeof SdkL1.getLecturerPortfolioSection>(SdkL1.getLecturerPortfolioSection)
export const getStudentPortfolioSectionWorks = refreshTokenOnError<SdkL1.getStudentPortfolioSectionWorksParams, SdkL1.getStudentPortfolioSectionWorksOk, SdkL1.getStudentPortfolioSectionWorksErr, typeof SdkL1.getStudentPortfolioSectionWorks>(SdkL1.getStudentPortfolioSectionWorks)
export const getStudentPortfolioSectionHistoryWorks = refreshTokenOnError<SdkL1.getStudentPortfolioSectionHistoryWorksParams, SdkL1.getStudentPortfolioSectionHistoryWorksOk, SdkL1.getStudentPortfolioSectionHistoryWorksErr, typeof SdkL1.getStudentPortfolioSectionHistoryWorks>(SdkL1.getStudentPortfolioSectionHistoryWorks)
export const getStudentPortfolioSectionCharts = refreshTokenOnError<SdkL1.getStudentPortfolioSectionChartsParams, SdkL1.getStudentPortfolioSectionChartsOk, SdkL1.getStudentPortfolioSectionChartsErr, typeof SdkL1.getStudentPortfolioSectionCharts>(SdkL1.getStudentPortfolioSectionCharts)
export const getLecturerPortfolioSectionWorks = refreshTokenOnError<SdkL1.getLecturerPortfolioSectionWorksParams, SdkL1.getLecturerPortfolioSectionWorksOk, SdkL1.getLecturerPortfolioSectionWorksErr, typeof SdkL1.getLecturerPortfolioSectionWorks>(SdkL1.getLecturerPortfolioSectionWorks)
export const getStudentPortfolioSectionWork = refreshTokenOnError<SdkL1.getStudentPortfolioSectionWorkParams, SdkL1.getStudentPortfolioSectionWorkOk, SdkL1.getStudentPortfolioSectionWorkErr, typeof SdkL1.getStudentPortfolioSectionWork>(SdkL1.getStudentPortfolioSectionWork)
export const getLecturerPortfolioSectionWork = refreshTokenOnError<SdkL1.getLecturerPortfolioSectionWorkParams, SdkL1.getLecturerPortfolioSectionWorkOk, SdkL1.getLecturerPortfolioSectionWorkErr, typeof SdkL1.getLecturerPortfolioSectionWork>(SdkL1.getLecturerPortfolioSectionWork)
export const getStudentPortfolioMentros = refreshTokenOnError<SdkL1.getStudentPortfolioMentrosParams, SdkL1.getStudentPortfolioMentrosOk, SdkL1.getStudentPortfolioMentrosErr, typeof SdkL1.getStudentPortfolioMentros>(SdkL1.getStudentPortfolioMentros)
export const getStudentPortfolioCompetencies = refreshTokenOnError<SdkL1.getStudentPortfolioCompetenciesParams, SdkL1.getStudentPortfolioCompetenciesOk, SdkL1.getStudentPortfolioCompetenciesErr, typeof SdkL1.getStudentPortfolioCompetencies>(SdkL1.getStudentPortfolioCompetencies)
export const getLecturerPortfolioCompetencies = refreshTokenOnError<SdkL1.getLecturerPortfolioCompetenciesParams, SdkL1.getLecturerPortfolioCompetenciesOk, SdkL1.getLecturerPortfolioCompetenciesErr, typeof SdkL1.getLecturerPortfolioCompetencies>(SdkL1.getLecturerPortfolioCompetencies)
export const getLecturerPortfolioSectionCompetencies = refreshTokenOnError<SdkL1.getLecturerPortfolioSectionCompetenciesParams, SdkL1.getLecturerPortfolioSectionCompetenciesOk, SdkL1.getLecturerPortfolioSectionCompetenciesErr, typeof SdkL1.getLecturerPortfolioSectionCompetencies>(SdkL1.getLecturerPortfolioSectionCompetencies)
export const putStudentPortfolioWork = refreshTokenOnError<SdkL1.putStudentPortfolioWorkParams, SdkL1.putStudentPortfolioWorkOk, SdkL1.putStudentPortfolioWorkErr, typeof SdkL1.putStudentPortfolioWork>(SdkL1.putStudentPortfolioWork)
export const putStudentPortfolioDraftWork = refreshTokenOnError<SdkL1.putStudentPortfolioDraftWorkParams, SdkL1.putStudentPortfolioDraftWorkOk, SdkL1.putStudentPortfolioDraftWorkErr, typeof SdkL1.putStudentPortfolioDraftWork>(SdkL1.putStudentPortfolioDraftWork)
export const patchStudentPortfolioWork = refreshTokenOnError<SdkL1.patchStudentPortfolioWorkParams, SdkL1.patchStudentPortfolioWorkOk, SdkL1.patchStudentPortfolioWorkErr, typeof SdkL1.patchStudentPortfolioWork>(SdkL1.patchStudentPortfolioWork)
export const postStudentPortfolioWork = refreshTokenOnError<SdkL1.postStudentPortfolioWorkParams, SdkL1.postStudentPortfolioWorkOk, SdkL1.postStudentPortfolioWorkErr, typeof SdkL1.postStudentPortfolioWork>(SdkL1.postStudentPortfolioWork)
export const patchLecturerPortfolioSectionsWorks = refreshTokenOnError<SdkL1.patchLecturerPortfolioSectionsWorksParams, SdkL1.patchLecturerPortfolioSectionsWorksOk, SdkL1.patchLecturerPortfolioSectionsWorksErr, typeof SdkL1.patchLecturerPortfolioSectionsWorks>(SdkL1.patchLecturerPortfolioSectionsWorks)
export const patchLecturerPortfolioSectionsWorksReject = refreshTokenOnError<SdkL1.patchLecturerPortfolioSectionsWorksRejectParams, SdkL1.patchLecturerPortfolioSectionsWorksRejectOk, SdkL1.patchLecturerPortfolioSectionsWorksRejectErr, typeof SdkL1.patchLecturerPortfolioSectionsWorksReject>(SdkL1.patchLecturerPortfolioSectionsWorksReject)
export const getStudentProgramsState = refreshTokenOnError<SdkL1.getStudentProgramsStateParams, SdkL1.getStudentProgramsStateOk, SdkL1.getStudentProgramsStateErr, typeof SdkL1.getStudentProgramsState>(SdkL1.getStudentProgramsState)
export const getSingleNews = refreshTokenOnError<SdkL1.getSingleNewsParams, SdkL1.getSingleNewsOk, SdkL1.getSingleNewsErr, typeof SdkL1.getSingleNews>(SdkL1.getSingleNews)
export const getNewsUnreadCount = refreshTokenOnError<SdkL1.getNewsUnreadCountParams, SdkL1.getNewsUnreadCountOk, SdkL1.getNewsUnreadCountErr, typeof SdkL1.getNewsUnreadCount>(SdkL1.getNewsUnreadCount)
export const getMediaFilesDownload = refreshTokenOnError<SdkL1.getMediaFilesDownloadParams, SdkL1.getMediaFilesDownloadOk, SdkL1.getMediaFilesDownloadErr, typeof SdkL1.getMediaFilesDownload>(SdkL1.getMediaFilesDownload)
export const getStudentFacultiesMinors = refreshTokenOnError<SdkL1.getStudentFacultiesMinorsParams, SdkL1.getStudentFacultiesMinorsOk, SdkL1.getStudentFacultiesMinorsErr, typeof SdkL1.getStudentFacultiesMinors>(SdkL1.getStudentFacultiesMinors)
export const getStudentPrograms = refreshTokenOnError<SdkL1.getStudentProgramsParams, SdkL1.getStudentProgramsOk, SdkL1.getStudentProgramsErr, typeof SdkL1.getStudentPrograms>(SdkL1.getStudentPrograms)
export const getStudentProgram = refreshTokenOnError<SdkL1.getStudentProgramParams, SdkL1.getStudentProgramOk, SdkL1.getStudentProgramErr, typeof SdkL1.getStudentProgram>(SdkL1.getStudentProgram)
export const getStudentFacultiesMinorPrograms = refreshTokenOnError<SdkL1.getStudentFacultiesMinorProgramsParams, SdkL1.getStudentFacultiesMinorProgramsOk, SdkL1.getStudentFacultiesMinorProgramsErr, typeof SdkL1.getStudentFacultiesMinorPrograms>(SdkL1.getStudentFacultiesMinorPrograms)
export const patchStudentProgramsState = refreshTokenOnError<SdkL1.patchStudentProgramsStateParams, SdkL1.patchStudentProgramsStateOk, SdkL1.patchStudentProgramsStateErr, typeof SdkL1.patchStudentProgramsState>(SdkL1.patchStudentProgramsState)
export const getStudentRecommendations = refreshTokenOnError<SdkL1.getStudentRecommendationsParams, SdkL1.getStudentRecommendationsOk, SdkL1.getStudentRecommendationsErr, typeof SdkL1.getStudentRecommendations>(SdkL1.getStudentRecommendations)
export const getNotifications = refreshTokenOnError<SdkL1.getNotificationsParams, SdkL1.getNotificationsOk, SdkL1.getNotificationsErr, typeof SdkL1.getNotifications>(SdkL1.getNotifications)
export const patchNotificationsMarkAllAsRead = refreshTokenOnError<SdkL1.patchNotificationsMarkAllAsReadParams, SdkL1.patchNotificationsMarkAllAsReadOk, SdkL1.patchNotificationsMarkAllAsReadErr, typeof SdkL1.patchNotificationsMarkAllAsRead>(SdkL1.patchNotificationsMarkAllAsRead)
export const patchNotificationsMarkAsRead = refreshTokenOnError<SdkL1.patchNotificationsMarkAsReadParams, SdkL1.patchNotificationsMarkAsReadOk, SdkL1.patchNotificationsMarkAsReadErr, typeof SdkL1.patchNotificationsMarkAsRead>(SdkL1.patchNotificationsMarkAsRead)
export const getStudentRecommendation = refreshTokenOnError<SdkL1.getStudentRecommendationParams, SdkL1.getStudentRecommendationOk, SdkL1.getStudentRecommendationErr, typeof SdkL1.getStudentRecommendation>(SdkL1.getStudentRecommendation)
export const postStudentRecommendationsRequest = refreshTokenOnError<SdkL1.postStudentRecommendationsRequestParams, SdkL1.postStudentRecommendationsRequestOk, SdkL1.postStudentRecommendationsRequestErr, typeof SdkL1.postStudentRecommendationsRequest>(SdkL1.postStudentRecommendationsRequest)
export const getStudentRecommendationsExport = refreshTokenOnError<SdkL1.getStudentRecommendationsExportParams, SdkL1.getStudentRecommendationsExportOk, SdkL1.getStudentRecommendationsExportErr, typeof SdkL1.getStudentRecommendationsExport>(SdkL1.getStudentRecommendationsExport)
export const getStudentResume = refreshTokenOnError<SdkL1.getStudentResumeParams, SdkL1.getStudentResumeOk, SdkL1.getStudentResumeErr, typeof SdkL1.getStudentResume>(SdkL1.getStudentResume)
export const getStudentResumePersonal = refreshTokenOnError<SdkL1.getStudentResumePersonalParams, SdkL1.getStudentResumePersonalOk, SdkL1.getStudentResumePersonalErr, typeof SdkL1.getStudentResumePersonal>(SdkL1.getStudentResumePersonal)
export const getStudentResumeWorks = refreshTokenOnError<SdkL1.getStudentResumeWorksParams, SdkL1.getStudentResumeWorksOk, SdkL1.getStudentResumeWorksErr, typeof SdkL1.getStudentResumeWorks>(SdkL1.getStudentResumeWorks)
export const getStudentResumeTrainings = refreshTokenOnError<SdkL1.getStudentResumeTrainingsParams, SdkL1.getStudentResumeTrainingsOk, SdkL1.getStudentResumeTrainingsErr, typeof SdkL1.getStudentResumeTrainings>(SdkL1.getStudentResumeTrainings)
export const getStudentResumeSkills = refreshTokenOnError<SdkL1.getStudentResumeSkillsParams, SdkL1.getStudentResumeSkillsOk, SdkL1.getStudentResumeSkillsErr, typeof SdkL1.getStudentResumeSkills>(SdkL1.getStudentResumeSkills)
export const getStudentResumeEducations = refreshTokenOnError<SdkL1.getStudentResumeEducationsParams, SdkL1.getStudentResumeEducationsOk, SdkL1.getStudentResumeEducationsErr, typeof SdkL1.getStudentResumeEducations>(SdkL1.getStudentResumeEducations)
export const getStudentResumeAdditional = refreshTokenOnError<SdkL1.getStudentResumeAdditionalParams, SdkL1.getStudentResumeAdditionalOk, SdkL1.getStudentResumeAdditionalErr, typeof SdkL1.getStudentResumeAdditional>(SdkL1.getStudentResumeAdditional)
export const getStudentResumeUniversities = refreshTokenOnError<SdkL1.getStudentResumeUniversitiesParams, SdkL1.getStudentResumeUniversitiesOk, SdkL1.getStudentResumeUniversitiesErr, typeof SdkL1.getStudentResumeUniversities>(SdkL1.getStudentResumeUniversities)
export const getStudentResumeLanguages = refreshTokenOnError<SdkL1.getStudentResumeLanguagesParams, SdkL1.getStudentResumeLanguagesOk, SdkL1.getStudentResumeLanguagesErr, typeof SdkL1.getStudentResumeLanguages>(SdkL1.getStudentResumeLanguages)
export const getStudentResumePublicationTypes = refreshTokenOnError<SdkL1.getStudentResumePublicationTypesParams, SdkL1.getStudentResumePublicationTypesOk, SdkL1.getStudentResumePublicationTypesErr, typeof SdkL1.getStudentResumePublicationTypes>(SdkL1.getStudentResumePublicationTypes)
export const getStudentResumeGrantTypes = refreshTokenOnError<SdkL1.getStudentResumeGrantTypesParams, SdkL1.getStudentResumeGrantTypesOk, SdkL1.getStudentResumeGrantTypesErr, typeof SdkL1.getStudentResumeGrantTypes>(SdkL1.getStudentResumeGrantTypes)
export const getStudentResumeCities = refreshTokenOnError<SdkL1.getStudentResumeCitiesParams, SdkL1.getStudentResumeCitiesOk, SdkL1.getStudentResumeCitiesErr, typeof SdkL1.getStudentResumeCities>(SdkL1.getStudentResumeCities)
export const getStudentResumeExport = refreshTokenOnError<SdkL1.getStudentResumeExportParams, SdkL1.getStudentResumeExportOk, SdkL1.getStudentResumeExportErr, typeof SdkL1.getStudentResumeExport>(SdkL1.getStudentResumeExport)
export const postStudentResumePersonal = refreshTokenOnError<SdkL1.postStudentResumePersonalParams, SdkL1.postStudentResumePersonalOk, SdkL1.postStudentResumePersonalErr, typeof SdkL1.postStudentResumePersonal>(SdkL1.postStudentResumePersonal)
export const postStudentResumeWorks = refreshTokenOnError<SdkL1.postStudentResumeWorksParams, SdkL1.postStudentResumeWorksOk, SdkL1.postStudentResumeWorksErr, typeof SdkL1.postStudentResumeWorks>(SdkL1.postStudentResumeWorks)
export const postStudentResumeTrainings = refreshTokenOnError<SdkL1.postStudentResumeTrainingsParams, SdkL1.postStudentResumeTrainingsOk, SdkL1.postStudentResumeTrainingsErr, typeof SdkL1.postStudentResumeTrainings>(SdkL1.postStudentResumeTrainings)
export const postStudentResumeSkills = refreshTokenOnError<SdkL1.postStudentResumeSkillsParams, SdkL1.postStudentResumeSkillsOk, SdkL1.postStudentResumeSkillsErr, typeof SdkL1.postStudentResumeSkills>(SdkL1.postStudentResumeSkills)
export const postStudentResumeEducations = refreshTokenOnError<SdkL1.postStudentResumeEducationsParams, SdkL1.postStudentResumeEducationsOk, SdkL1.postStudentResumeEducationsErr, typeof SdkL1.postStudentResumeEducations>(SdkL1.postStudentResumeEducations)
export const postStudentResumeAdditional = refreshTokenOnError<SdkL1.postStudentResumeAdditionalParams, SdkL1.postStudentResumeAdditionalOk, SdkL1.postStudentResumeAdditionalErr, typeof SdkL1.postStudentResumeAdditional>(SdkL1.postStudentResumeAdditional)
export const patchStudentResumeTabs = refreshTokenOnError<SdkL1.patchStudentResumeTabsParams, SdkL1.patchStudentResumeTabsOk, SdkL1.patchStudentResumeTabsErr, typeof SdkL1.patchStudentResumeTabs>(SdkL1.patchStudentResumeTabs)
export const getStudentAvailableTabs = refreshTokenOnError<SdkL1.getStudentAvailableTabsParams, SdkL1.getStudentAvailableTabsOk, SdkL1.getStudentAvailableTabsErr, typeof SdkL1.getStudentAvailableTabs>(SdkL1.getStudentAvailableTabs)
export const getStudentAvailableChoices = refreshTokenOnError<SdkL1.getStudentAvailableChoicesParams, SdkL1.getStudentAvailableChoicesOk, SdkL1.getStudentAvailableChoicesErr, typeof SdkL1.getStudentAvailableChoices>(SdkL1.getStudentAvailableChoices)
export const getStudentAvailableFreeChoices = refreshTokenOnError<SdkL1.getStudentAvailableFreeChoicesParams, SdkL1.getStudentAvailableFreeChoicesOk, SdkL1.getStudentAvailableFreeChoicesErr, typeof SdkL1.getStudentAvailableFreeChoices>(SdkL1.getStudentAvailableFreeChoices)
export const getStudentAvailableGeneralCourses = refreshTokenOnError<SdkL1.getStudentAvailableGeneralCoursesParams, SdkL1.getStudentAvailableGeneralCoursesOk, SdkL1.getStudentAvailableGeneralCoursesErr, typeof SdkL1.getStudentAvailableGeneralCourses>(SdkL1.getStudentAvailableGeneralCourses)
export const getStudentAvailableGeneralChoices = refreshTokenOnError<SdkL1.getStudentAvailableGeneralChoicesParams, SdkL1.getStudentAvailableGeneralChoicesOk, SdkL1.getStudentAvailableGeneralChoicesErr, typeof SdkL1.getStudentAvailableGeneralChoices>(SdkL1.getStudentAvailableGeneralChoices)
export const getStudentAvailableProgramsCourses = refreshTokenOnError<SdkL1.getStudentAvailableProgramsCoursesParams, SdkL1.getStudentAvailableProgramsCoursesOk, SdkL1.getStudentAvailableProgramsCoursesErr, typeof SdkL1.getStudentAvailableProgramsCourses>(SdkL1.getStudentAvailableProgramsCourses)
export const getStudentAvailableProgramsChoices = refreshTokenOnError<SdkL1.getStudentAvailableProgramsChoicesParams, SdkL1.getStudentAvailableProgramsChoicesOk, SdkL1.getStudentAvailableProgramsChoicesErr, typeof SdkL1.getStudentAvailableProgramsChoices>(SdkL1.getStudentAvailableProgramsChoices)
export const getStudentFacultiesPrograms = refreshTokenOnError<SdkL1.getStudentFacultiesProgramsParams, SdkL1.getStudentFacultiesProgramsOk, SdkL1.getStudentFacultiesProgramsErr, typeof SdkL1.getStudentFacultiesPrograms>(SdkL1.getStudentFacultiesPrograms)
export const getStudentLecturersCurrent = refreshTokenOnError<SdkL1.getStudentLecturersCurrentParams, SdkL1.getStudentLecturersCurrentOk, SdkL1.getStudentLecturersCurrentErr, typeof SdkL1.getStudentLecturersCurrent>(SdkL1.getStudentLecturersCurrent)
export const getStudentFeedbacksSubjects = refreshTokenOnError<SdkL1.getStudentFeedbacksSubjectsParams, SdkL1.getStudentFeedbacksSubjectsOk, SdkL1.getStudentFeedbacksSubjectsErr, typeof SdkL1.getStudentFeedbacksSubjects>(SdkL1.getStudentFeedbacksSubjects)
export const postStudentFeedbacks = refreshTokenOnError<SdkL1.postStudentFeedbacksParams, SdkL1.postStudentFeedbacksOk, SdkL1.postStudentFeedbacksErr, typeof SdkL1.postStudentFeedbacks>(SdkL1.postStudentFeedbacks)
export const getStudentFeedback = refreshTokenOnError<SdkL1.getStudentFeedbackParams, SdkL1.getStudentFeedbackOk, SdkL1.getStudentFeedbackErr, typeof SdkL1.getStudentFeedback>(SdkL1.getStudentFeedback)
export const getStudentFeedbacks = refreshTokenOnError<SdkL1.getStudentFeedbacksParams, SdkL1.getStudentFeedbacksOk, SdkL1.getStudentFeedbacksErr, typeof SdkL1.getStudentFeedbacks>(SdkL1.getStudentFeedbacks)
export const getStudentChosenGroup = refreshTokenOnError<SdkL1.getStudentChosenGroupParams, SdkL1.getStudentChosenGroupOk, SdkL1.getStudentChosenGroupErr, typeof SdkL1.getStudentChosenGroup>(SdkL1.getStudentChosenGroup)
export const getStudentSurveyActive = refreshTokenOnError<SdkL1.getStudentSurveyActiveParams, SdkL1.getStudentSurveyActiveOk, SdkL1.getStudentSurveyActiveErr, typeof SdkL1.getStudentSurveyActive>(SdkL1.getStudentSurveyActive)
export const getStudentSurveyQuestions = refreshTokenOnError<SdkL1.getStudentSurveyQuestionsParams, SdkL1.getStudentSurveyQuestionsOk, SdkL1.getStudentSurveyQuestionsErr, typeof SdkL1.getStudentSurveyQuestions>(SdkL1.getStudentSurveyQuestions)
export const postStudentSurveyQuestionsAnswers = refreshTokenOnError<SdkL1.postStudentSurveyQuestionsAnswersParams, SdkL1.postStudentSurveyQuestionsAnswersOk, SdkL1.postStudentSurveyQuestionsAnswersErr, typeof SdkL1.postStudentSurveyQuestionsAnswers>(SdkL1.postStudentSurveyQuestionsAnswers)
export const postStudentSurveyClose = refreshTokenOnError<SdkL1.postStudentSurveyCloseParams, SdkL1.postStudentSurveyCloseOk, SdkL1.postStudentSurveyCloseErr, typeof SdkL1.postStudentSurveyClose>(SdkL1.postStudentSurveyClose)
export const postStudentSurveyFinish = refreshTokenOnError<SdkL1.postStudentSurveyFinishParams, SdkL1.postStudentSurveyFinishOk, SdkL1.postStudentSurveyFinishErr, typeof SdkL1.postStudentSurveyFinish>(SdkL1.postStudentSurveyFinish)
export const getLecturerSurveyActive = refreshTokenOnError<SdkL1.getLecturerSurveyActiveParams, SdkL1.getLecturerSurveyActiveOk, SdkL1.getLecturerSurveyActiveErr, typeof SdkL1.getLecturerSurveyActive>(SdkL1.getLecturerSurveyActive)
export const getLecturerSurveyQuestions = refreshTokenOnError<SdkL1.getLecturerSurveyQuestionsParams, SdkL1.getLecturerSurveyQuestionsOk, SdkL1.getLecturerSurveyQuestionsErr, typeof SdkL1.getLecturerSurveyQuestions>(SdkL1.getLecturerSurveyQuestions)
export const postLecturerSurveyQuestionsAnswers = refreshTokenOnError<SdkL1.postLecturerSurveyQuestionsAnswersParams, SdkL1.postLecturerSurveyQuestionsAnswersOk, SdkL1.postLecturerSurveyQuestionsAnswersErr, typeof SdkL1.postLecturerSurveyQuestionsAnswers>(SdkL1.postLecturerSurveyQuestionsAnswers)
export const postLecturerSurveyClose = refreshTokenOnError<SdkL1.postLecturerSurveyCloseParams, SdkL1.postLecturerSurveyCloseOk, SdkL1.postLecturerSurveyCloseErr, typeof SdkL1.postLecturerSurveyClose>(SdkL1.postLecturerSurveyClose)
export const postLecturerSurveyFinish = refreshTokenOnError<SdkL1.postLecturerSurveyFinishParams, SdkL1.postLecturerSurveyFinishOk, SdkL1.postLecturerSurveyFinishErr, typeof SdkL1.postLecturerSurveyFinish>(SdkL1.postLecturerSurveyFinish)
export const getLecturerCoursesDiscussions = refreshTokenOnError<SdkL1.getLecturerCoursesDiscussionsParams, SdkL1.getLecturerCoursesDiscussionsOk, SdkL1.getLecturerCoursesDiscussionsErr, typeof SdkL1.getLecturerCoursesDiscussions>(SdkL1.getLecturerCoursesDiscussions)
export const postLecturerCoursesDiscussions = refreshTokenOnError<SdkL1.postLecturerCoursesDiscussionsParams, SdkL1.postLecturerCoursesDiscussionsOk, SdkL1.postLecturerCoursesDiscussionsErr, typeof SdkL1.postLecturerCoursesDiscussions>(SdkL1.postLecturerCoursesDiscussions)
export const deleteLecturerCoursesDiscussions = refreshTokenOnError<SdkL1.deleteLecturerCoursesDiscussionsParams, SdkL1.deleteLecturerCoursesDiscussionsOk, SdkL1.deleteLecturerCoursesDiscussionsErr, typeof SdkL1.deleteLecturerCoursesDiscussions>(SdkL1.deleteLecturerCoursesDiscussions)
export const patchLecturerCoursesDiscussions = refreshTokenOnError<SdkL1.patchLecturerCoursesDiscussionsParams, SdkL1.patchLecturerCoursesDiscussionsOk, SdkL1.patchLecturerCoursesDiscussionsErr, typeof SdkL1.patchLecturerCoursesDiscussions>(SdkL1.patchLecturerCoursesDiscussions)
export const getLecturerCoursesDiscussion = refreshTokenOnError<SdkL1.getLecturerCoursesDiscussionParams, SdkL1.getLecturerCoursesDiscussionOk, SdkL1.getLecturerCoursesDiscussionErr, typeof SdkL1.getLecturerCoursesDiscussion>(SdkL1.getLecturerCoursesDiscussion)
export const getLecturerCoursesDiscussionsComments = refreshTokenOnError<SdkL1.getLecturerCoursesDiscussionsCommentsParams, SdkL1.getLecturerCoursesDiscussionsCommentsOk, SdkL1.getLecturerCoursesDiscussionsCommentsErr, typeof SdkL1.getLecturerCoursesDiscussionsComments>(SdkL1.getLecturerCoursesDiscussionsComments)
export const postLecturerCoursesDiscussionsComments = refreshTokenOnError<SdkL1.postLecturerCoursesDiscussionsCommentsParams, SdkL1.postLecturerCoursesDiscussionsCommentsOk, SdkL1.postLecturerCoursesDiscussionsCommentsErr, typeof SdkL1.postLecturerCoursesDiscussionsComments>(SdkL1.postLecturerCoursesDiscussionsComments)
export const deleteLecturerCoursesDiscussionsComments = refreshTokenOnError<SdkL1.deleteLecturerCoursesDiscussionsCommentsParams, SdkL1.deleteLecturerCoursesDiscussionsCommentsOk, SdkL1.deleteLecturerCoursesDiscussionsCommentsErr, typeof SdkL1.deleteLecturerCoursesDiscussionsComments>(SdkL1.deleteLecturerCoursesDiscussionsComments)
