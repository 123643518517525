import { setUser } from '@sentry/react'
import React, { useEffect, useRef } from 'react'
import { useNavigate, type NavigateFunction } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getAuthorization } from 'src/api/common'
import { loginWithGoogleAccessToken } from 'src/api/sdk-l1'
import { getAuthUser } from 'src/api/sdk-l2'
import type * as Types from 'src/api/types'
import { useStorageValue } from 'src/helpers/storage'
import { useLocale } from '../locale/locale'
import { useTranslatable } from '../locale/utils'
import { useMaintenanceModeState } from '../maintenanceMode'
import { authEventEmitter } from './authEventEmitter'
import { authObservableValue, useAuth, type State } from './authObservableValue'
import { refreshTokenStorage } from './refreshTokenStorage'

export * from './authObservableValue'

export function AuthManager(): null {
  const [, setMaintenanceMode] = useMaintenanceModeState()
  const refreshToken = useStorageValue(refreshTokenStorage)
  const actions = useAuthActions()
  const auth = useAuth()
  const locale = useLocale()
  const navigate = useNavigate()

  const actionsRef = useRef<AuthActions>(actions)
  const authRef = useRef<State>(auth)
  const navigateRef = useRef<NavigateFunction>(navigate)
  const localeRef = useRef<Types.LanguageString>(locale)

  actionsRef.current = actions
  authRef.current = auth
  navigateRef.current = navigate
  localeRef.current = locale

  setUser({
    id: auth.user?.id,
    profileId: auth.profile?.id,
    fullName: auth.user?.fullName,
    email: auth.user?.email,
    personalNumber: auth.user?.personalNumber,
  })

  useEffect(() => {
    void (async () => {
      if (refreshToken?.authObject == null) {
        authObservableValue.setValue({
          state: 'Unauthenticated',
        })
      } else if (authRef.current.state === 'Undetermined' || authRef.current.state === 'Unauthenticated') {
        try {
          const oldState = authRef.current.state

          const user = await getAuthUser({
            headers: {
              'Accept-Language': localeRef.current,
              Authorization: getAuthorization(refreshToken.authObject),
            },
          })

          const profile = user.profiles.find((p) => p.id === refreshToken.profileId) ?? user.profiles[0]!

          refreshTokenStorage.setValue({
            profileId: profile.id,
            authObject: refreshTokenStorage.getValue()!.authObject,
          })

          authObservableValue.setValue({
            state: 'Authenticated',
            user,
            profile,
          })

          if (oldState === 'Unauthenticated') {
            navigateRef.current(`/${localeRef.current}`)
          }
        } catch (err) {
          console.error(err)

          authObservableValue.setValue({
            state: 'Unauthenticated',
          })

          if (typeof err === 'object' && err != null && 'type' in err && err.type === 'MaintenanceMode') {
            setMaintenanceMode(true)
          }
        }
      } else {
        const user = authRef.current.user
        const profile = user.profiles.find((p) => p.id === refreshToken.profileId) ?? user.profiles[0]!

        authObservableValue.setValue({
          state: 'Authenticated',
          user,
          profile,
        })

        // await navigateRef.current(`/${localeRef.current}`)
      }
    })()
  }, [refreshToken?.authObject, refreshToken?.profileId, setMaintenanceMode])

  useEffect(() => {
    const fn = (payload: google.accounts.oauth2.TokenResponse): void => {
      void actionsRef.current?.GOOGLE_TOKEN_RESPONSE(payload)
    }

    authEventEmitter.addListener('GOOGLE_TOKEN_RESPONSE', fn)

    return () => {
      authEventEmitter.removeListener('GOOGLE_TOKEN_RESPONSE', fn)
    }
  }, [])

  useEffect(() => {
    void (async () => {
      if (authRef.current.state !== 'Authenticated') return void null

      try {
        const user = await getAuthUser({
          headers: {
            'Accept-Language': locale,
            Authorization: getAuthorization(refreshTokenStorage.getValue()!.authObject),
          },
        })

        const profile = user.profiles.find((p) => p.id === authRef.current.profile?.id) ?? user.profiles[0]!

        authObservableValue.setValue({
          state: 'Authenticated',
          user,
          profile,
        })
      } catch (err) {
        console.error(err)

        if (typeof err === 'object' && err != null && 'type' in err && err.type === 'MaintenanceMode') {
          setMaintenanceMode(true)
        }
      }
    })()
  }, [locale, setMaintenanceMode])

  return null
}

export interface AuthActions {
  readonly GOOGLE_TOKEN_RESPONSE: (response: google.accounts.oauth2.TokenResponse) => Promise<void>
  readonly SWITCH_PROFILE: (profile: Types.UserProfile) => Promise<void>
  readonly LOGOUT: () => Promise<void>
}

export function useAuthActions(): AuthActions {
  const state = useAuth()
  const navigate = useNavigate()
  const locale = useLocale()
  const t = useTranslatable()

  const stateRef = useRef(state)
  stateRef.current = state

  const LOGOUT = React.useCallback(async (): Promise<void> => {
    if (state.state !== 'Authenticated') return void null

    navigate(`/${locale}`)

    refreshTokenStorage.setValue(null)

    authObservableValue.setValue({
      state: 'Unauthenticated',
    })

    toast.success(t('system:successfully_logout'))
  }, [locale, navigate, state.state, t])

  const SWITCH_PROFILE = React.useCallback(
    async (profile: Types.UserProfile): Promise<void> => {
      if (state.state !== 'Authenticated') return void null

      authObservableValue.setValue({
        state: 'Undetermined',
      })

      refreshTokenStorage.setValue({
        profileId: profile.id,
        authObject: refreshTokenStorage.getValue()!.authObject,
      })

      authObservableValue.setValue({
        state: 'Authenticated',
        user: state.user,
        profile,
      })

      navigate(`/${locale}`)
    },
    [locale, navigate, state.state, state.user]
  )

  const GOOGLE_TOKEN_RESPONSE = React.useCallback(
    async (response: google.accounts.oauth2.TokenResponse) => {
      try {
        authObservableValue.setValue({
          state: 'Undetermined',
        })

        const authObject = await loginWithGoogleAccessToken({
          body: {
            clientId: '1',
            grantType: 'google_access_token',
            token: response.access_token,
          },
          headers: { 'Accept-Language': locale },
        })

        const user = await getAuthUser({
          headers: {
            'Accept-Language': locale,
            Authorization: getAuthorization(authObject),
          },
        })

        const profile = user.profiles[0]!

        refreshTokenStorage.setValue({
          authObject,
          profileId: profile.id,
        })

        authObservableValue.setValue({
          state: 'Authenticated',
          user,
          profile,
        })
      } catch {
        authObservableValue.setValue({
          state: 'Unauthenticated',
        })

        toast.error(t('system:authentication_failed'))
      }
    },
    [locale, t]
  )

  return React.useMemo(
    () => ({
      GOOGLE_TOKEN_RESPONSE,
      LOGOUT,
      SWITCH_PROFILE,
    }),
    [GOOGLE_TOKEN_RESPONSE, LOGOUT, SWITCH_PROFILE]
  )
}

export interface AuthArgs {
  readonly authObject: Types.AuthObject
  readonly user: Types.AuthUser
  readonly profile: Types.UserProfile
}
