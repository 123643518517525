import env from 'src/helpers/env'

export interface UploadConfig {
  readonly url: string
  readonly max_upload_size: number
  readonly max_chunk_size: number
  readonly allowed_extensions: readonly string[]
}

const defaultUploadConfig = {
  url: env.SERVICE_URL_V2 + '/media-files/upload',
  max_upload_size: 1073741824,
  max_chunk_size: 52428800,
  allowed_extensions: [
    'jpeg',
    'jpg',
    'bmp',
    'gif',
    'png',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'pdf',
    'rtf',
    'ppt',
    'pptx',
    'djvu',
    'mp4',
    'm4v',
    'flv',
    'wma',
    'wav',
    'amr',
    'flac',
    'm4a',
    'ogg',
    'mov',
    'aac',
    'swf',
    'avi',
    'wmv',
    'mp3',
  ],
}
export const UploadConfigMap = {
  default: defaultUploadConfig,
  studentStatements: {
    ...defaultUploadConfig,
    url: env.SERVICE_URL_V2 + '/student/statements/media-files/upload',
    max_upload_size: 104857600,
    allowed_extensions: [
      'jpeg',
      'jpg',
      'bmp',
      'gif',
      'png',
      'doc',
      'docx',
      'xls',
      'xlsx',
      'pdf',
      'rtf',
      'ppt',
      'pptx',
      'djvu',
    ],
  },
  registration: {
    ...defaultUploadConfig,
    url: env.SERVICE_URL_V2 + '/registration/media-files/upload',
    max_upload_size: 1073741824,
    max_chunk_size: 52428800,
    allowed_extensions: ['pdf'], // 'jpeg', 'jpg', 'png',
  },
  registrationPhoto: {
    ...defaultUploadConfig,
    url: env.SERVICE_URL_V2 + '/registration/media-files/upload',
    max_upload_size: 1073741824,
    max_chunk_size: 52428800,
    allowed_extensions: ['jpeg', 'jpg', 'png'],
  },
  studentResumePersonal: {
    ...defaultUploadConfig,
    url: env.SERVICE_URL_V2 + '/student/resume/personal/media-files/upload',
    max_upload_size: 50000000,
    allowed_extensions: ['jpeg', 'jpg', 'png'],
  },
  studentResumeTrainings: {
    ...defaultUploadConfig,
    url: env.SERVICE_URL_V2 + '/student/resume/trainings/media-files/upload',
    max_upload_size: 10000000,
    allowed_extensions: ['jpeg', 'jpg', 'png', 'pdf'],
  },
  studentResumeEducations: {
    ...defaultUploadConfig,
    url: env.SERVICE_URL_V2 + '/student/resume/educations/media-files/upload',
    max_upload_size: 10000000,
    allowed_extensions: ['jpeg', 'jpg', 'png', 'pdf'],
  },
  studentFeedbacks: {
    ...defaultUploadConfig,
    url: env.SERVICE_URL_V2 + '/student/feedbacks/media-files',
    max_upload_size: 50000000,
    allowed_extensions: ['jpeg', 'jpg', 'png', 'pdf', 'doc', 'docx'],
  },

  studentPortfolio: (id: string) => ({
    ...defaultUploadConfig,
    url: env.SERVICE_URL_V2 + `/student/portfolio/sections/${id}/upload`,
    max_upload_size: 104857600,
    allowed_extensions: [
      'jpeg',
      'jpg',
      'bmp',
      'gif',
      'png',
      'doc',
      'docx',
      'xls',
      'xlsx',
      'pdf',
      'rtf',
      'ppt',
      'pptx',
      'djvu',
    ],
  }),

  lecturerFeedbacks: {
    ...defaultUploadConfig,
    url: env.SERVICE_URL_V2 + '/lecturer/feedbacks/media-files',
    max_upload_size: 50000000,
    allowed_extensions: ['jpeg', 'jpg', 'png', 'pdf', 'doc', 'docx'],
  },
  lecturerCoursesMaterialsUpload: (id: string) => ({
    ...defaultUploadConfig,
    url: env.SERVICE_URL_V2 + `/lecturer/courses/${id}/materials/upload`,
    max_upload_size: 1073741824,
    allowed_extensions: ['jpeg', 'jpg', 'png', 'pdf', 'doc', 'docx', 'mp3', 'mp4', 'ppt', 'pptx', 'xls', 'xlsx'],
  }),
} as const
